// @ts-nocheck
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Panel_switcher {
  check: string = 'HOT_SOFTWARE';

  get switch_state(): any {
    return this.check;
  }

  setSwich(flag) {
    if (flag) this.check = 'IKBS_TOOL';
    if (!flag) this.check = 'HOT_SOFTWARE';
  }
}
